import * as i0 from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Inject, Self, NgModule } from '@angular/core';
import * as i2 from '@taiga-ui/cdk';
import { TuiDestroyService, TUI_PARENT_ANIMATION, TuiActiveZoneModule } from '@taiga-ui/cdk';
import { TuiHintService } from '@taiga-ui/core/services';
import { takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i4 from 'rxjs';
const _c0 = a0 => ({
  $implicit: a0
});
function TuiHintsHostComponent_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiHintsHostComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, TuiHintsHostComponent_div_0_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const hint_r1 = ctx.$implicit;
    i0.ɵɵproperty("@tuiParentAnimation", undefined)("tuiActiveZoneParent", hint_r1.activeZone || null);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", hint_r1.component)("polymorpheusOutletContext", i0.ɵɵpureFunction1(4, _c0, hint_r1));
  }
}
class TuiHintsHostComponent {
  constructor(hints$, destroy$, cdr) {
    this.hints$ = hints$;
    this.destroy$ = destroy$;
    this.cdr = cdr;
    this.hints = [];
  }
  ngOnInit() {
    // Due to this view being parallel to app content, `markForCheck` from `async` pipe
    // can happen after view was checked, so calling `detectChanges` instead
    this.hints$.pipe(takeUntil(this.destroy$)).subscribe(hints => {
      this.hints = hints;
      this.cdr.detectChanges();
    });
  }
}
TuiHintsHostComponent.ɵfac = function TuiHintsHostComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiHintsHostComponent)(i0.ɵɵdirectiveInject(TuiHintService), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(ChangeDetectorRef));
};
TuiHintsHostComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiHintsHostComponent,
  selectors: [["tui-hints-host"]],
  hostAttrs: ["aria-live", "polite"],
  standalone: false,
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])],
  decls: 1,
  vars: 1,
  consts: [["role", "tooltip", 3, "tuiActiveZoneParent", 4, "ngFor", "ngForOf"], ["role", "tooltip", 3, "tuiActiveZoneParent"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"]],
  template: function TuiHintsHostComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiHintsHostComponent_div_0_Template, 2, 6, "div", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", ctx.hints);
    }
  },
  dependencies: [i1.NgForOf, i2.TuiActiveZoneDirective, i3.PolymorpheusOutletDirective],
  styles: ["[_nghost-%COMP%]{position:fixed;top:0;left:0;width:100%;height:0}"],
  data: {
    animation: [TUI_PARENT_ANIMATION]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintsHostComponent, [{
    type: Component,
    args: [{
      selector: 'tui-hints-host',
      templateUrl: './hints-host.template.html',
      styleUrls: ['./hints-host.style.less'],
      // So that we do not force OnPush on custom hints
      // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
      changeDetection: ChangeDetectionStrategy.Default,
      providers: [TuiDestroyService],
      animations: [TUI_PARENT_ANIMATION],
      host: {
        'aria-live': 'polite'
      }
    }]
  }], function () {
    return [{
      type: i4.Observable,
      decorators: [{
        type: Inject,
        args: [TuiHintService]
      }]
    }, {
      type: i4.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }];
  }, null);
})();
class TuiHintsHostModule {}
TuiHintsHostModule.ɵfac = function TuiHintsHostModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiHintsHostModule)();
};
TuiHintsHostModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiHintsHostModule
});
TuiHintsHostModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule, TuiActiveZoneModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintsHostModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiActiveZoneModule],
      declarations: [TuiHintsHostComponent],
      exports: [TuiHintsHostComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiHintsHostComponent, TuiHintsHostModule };
